<template lang="pug">
v-container#abogados
  
  v-row(justify='center').align-center
    
    v-col(cols=12 sm=6)
    
      v-carousel(hide-delimiters='' v-model='step' :show-arrows="false")
        div(style='position: absolute; margin: auto 0; left: 0; bottom:0; top: 0;  height: 20px')
          v-icon(@click='step--').primary--text fa-angle-left
        div(style='position: absolute; margin: auto 0; right: 0; bottom:0; top: 0;  height: 20px')
          v-icon(@click='step++').primary--text fa-angle-right
        
        template(v-for='par, i of equipo')
          v-carousel-item.px-8
            v-row.px-5.fill-height.align-center
              template( v-for='item of par')
                v-col(  cols=6)
                  v-hover( v-slot:default="{ hover }")
                    v-card(tile flat :href='item.Cv' target='_blanck').transparent
                      v-img( :src='item.foto' height='280px')
                        v-expand-transition
                          small(
                            v-if="hover"
                            class="d-flex align-center transition-fast-in-fast-out primary  v-card--reveal white--text text-end"
                            style="height: 20%;"
                          )
                            p.white--text.text-center.pa-3( v-html='item.button' )
                      h3.grey--text.text--darken-2.mt-5.text-center( v-html='item.nombre' )
                      p.primary--text.text-center.text-uppercase.caption( v-html='item.cargo' )
                        
    v-col(cols=12 sm=6)
      div.d-flex.flex-column.mb-5.align-center
        v-card-text
          h2.primary--text.text-center(v-html='objeto.titulo')
        .subrayado( style='width:50px')
        .subrayado( style='width:30px')
        .subrayado( style='width:10px')
        v-card-text
          h4.text-justify.gris--text.font-weight-light( v-html='objeto.cuerpo') 
        v-card-actions
          v-row( justify='center' align='center' )
      
  


</template>

<script>
export default {
  data: ()=>({
    step: 0
  }),
  computed: {
    equipo(){
      let arreglo = [] 
      let par = []
      if (!this.objeto.equipo) return
      this.objeto.equipo.map( (item, index ) => {
        par.push( item )
        if( index % 2 != 0 || index ==this.objeto.equipo.length-1) {
          arreglo.push(par)
          par = []
        }
      })
      return arreglo
    }
  },
  props: ['objeto']
}
</script>